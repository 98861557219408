const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://quangninh-api.cgis.asia/",
        media_url: "https://quangninh-api.cgis.asia/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "http://localhost:3001",
        domainName: "",
        workSpace: "quangninh",
        wmsBaseLink: "https://geo.cgis.asia/geoserver/quangninh/wms/",
  },
  production: {
      api: "https://quangninh-api.cgis.asia/",
      media_url: "https://quangninh-api.cgis.asia/",
      domainAdminSide: "https://admin.quangninh.cgis.asia",
      domainUserSide: "https://quangninh.cgis.asia",
      domainName: "quangninh.cgis.asia",
      workSpace: "quangninh",
      wmsBaseLink: "https://geo.cgis.asia/geoserver/quangninh/wms/",
  },
};

module.exports = apiEnvironment[env];
