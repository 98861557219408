/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  DomainAdminSide,
  TokenKey,
  getUserInfo,
  removeCookies,
  APIUrlDefault,
  setCookies,
} from "../../utils/configuration";

export default class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: getUserInfo() ? true : false,
      user: getUserInfo(),
    };
  }

  UNSAFE_componentWillMount() {
    const { isLogin, user } = this.state;
    if (isLogin && user && user.userRole) return;
    else {
      removeCookies(TokenKey.returnUrl);
      setCookies(TokenKey.returnUrl, window.location.href);
        window.location.replace(DomainAdminSide + "/dang-nhap");
    }
  }

  onLogout() {
    removeCookies(TokenKey.token);
    removeCookies(TokenKey.returnUrl);
    window.location.replace(DomainAdminSide);
  }

  render() {
    const { user } = this.state;

    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {user && user.fullName}
              </span>
              <img
                className="img-profile rounded-circle"
                src={//require("../../assets/images/user-default.png"
                  user && user.avatar && user.avatar !== "null"
                    ? APIUrlDefault + user.avatar
                        // : process.env.PUBLIC_URL + "/user-default.png"
                        : process.env.PUBLIC_URL + "/logo.png"
                }
              />
            </a>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              {/* <a className="dropdown-item" href="#">
                <FontAwesomeIcon icon={faUser} className="fa-sm fa-fw mr-2 text-gray-400" />Profile
              </a> */}
              {/* <div className="dropdown-divider"></div> */}
              <a className="dropdown-item" href="" onClick={this.onLogout}>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  className="fa-sm fa-fw mr-2 text-gray-400"
                />
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}
